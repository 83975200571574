<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="pull-left">
          <h3>
            {{$t('시스템 관리')}} > {{$t('계통 및 설비')}} > {{$t('설비 제어항목 설정')}}
          </h3>
        </div>
      </div>
    </div>

    <hr class="mt-3 mb-3" />

    <div class="row">
      <div class="col-lg-4">
        <type-control-list @selectType="selectTypeEmit"></type-control-list>
      </div>

      <div class="col-lg-8">
        <type-control-detail :data="items"></type-control-detail>
      </div>
    </div>
  </div>
</template>

<script>
// import backEndApi from "../../../../api/backEndApi";

import typeControlList from "./TypeControlList.vue";
import typeControlDetail from "./TypeControlDetail.vue";

export default {
  name: 'TypeControl',
  props: [],
  components: {
    typeControlList,
    typeControlDetail,
  },
  watch: {},
  data() {
    return {
      items: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    selectTypeEmit(value) {
      this.items = value;
    },

    newModal() {
      this.$refs.newModal.show();
    },
    hide() {
      this.$refs.newModal.hide();
    },
    reSearch() {
      this.$refs.search.reSearch();
    },
  },
};
</script>

<style scoped></style>
