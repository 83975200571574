<template>
  <div class="TypeControlDetailNew mt-1 text-left">
    <div class="row">
      <div class="col-lg-2 labels">
        <a>{{$t('제어 그룹명')}}</a>
      </div>
      <div class="col-lg-10">
        <b-form-select v-model="isNew.ctrlCode" :options="ctrlGrpOption"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 labels">
        <a>{{$t('제어 항목명')}}</a>
      </div>
      <div class="col-lg-10">
        <!-- <b-form-input v-model="isNew.equipCtrlName"></b-form-input> -->
        <valid-input :vid="'제어 항목명'" :inputType="'text'" :inputValue.sync="isNew.equipCtrlName"
          :rules="rules.EQUIP_SYSTEM_CONTROL_NAME_RULE"
          :errorMessage="validMessage.EQUIP_SYSTEM_CONTROL_NAME_VALID_MESSAGE"></valid-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 labels">
        <a>{{$t('적용 항목')}}</a>
      </div>
      <div class="col-lg-10 text-right">
        <b-button @click="rowInsert">{{$t('행 추가')}}</b-button>
        <b-button @click="rowRemove">{{$t('선택행 삭제')}}</b-button>
      </div>
    </div>
    <div class="row" :style="{ justifyContent: 'center' }">
      <b-table hover bordered :class="'text-center mb-0 rounded'" head-variant="dark" no-border-collapse show-empty
        :select-mode="'single'" selectable ref="selectableTable" :empty-text="$t('등록된 항목이 없습니다.')" :style="{ width: '100%' }"
        :items="items" :fields="fields" @row-clicked="onRowSelected">
        <template #cell(checked)="data">
          <b-form-checkbox v-model="data.item.checked"></b-form-checkbox>
        </template>

        <template #cell(propCodeName)="data">
          <b-form-select v-model="data.item.propCode" :options="ctrlDataOptions"
            @input="setPropName(data.item)"></b-form-select>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col-lg-2 labels">
        <a>{{$t('사용여부')}}</a>
      </div>
      <div class="col-lg-4">
        <b-form-select v-model="isNew.enabled" :options="enabledOptions"></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi.js";
import AppErrorHandler from "@src/appErrorHandler";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";
import * as popupMessages from "@src/consts/popupMessageConsts.js";
import { StatusCodes } from "http-status-codes";

export default {
  name: "TypeControlDetailInTable",
  props: ["isNew"],
  data() {
    return {
      items: [],
      fields: [],
      ctrlEnabledOptions: [],
      ctrlGrpOption: [],
      controlGrpValue: "",
      enabledOptions: [
        { value: "Y", text: global.xe.$t("사용") },
        { value: "N", text: global.xe.$t("사용 안함") },
      ],
      rules,
      validMessage
    };
  },

  async created() {
    this.ctrlDataOptions = await this.getEquipPropList("Y");
    this.ctrlGrpOption = await this.getEquipPropList("Grp");
    this.setFields();
    this.setItems();
  },

  methods: {
    async getEquipPropList(ctrlOptCode) {
      // console.log(this.rowData, this.detailData);
      let row = this.isNew;

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {

        let result = await backEndApi.equipType.getEquipPropList(row.equipTypeCode, row.enabled, ctrlOptCode);
        let options = [];

        for (let i = 0; i < result.data.length; i++) {
          let option = {
            value: result.data[i].equipPropCode,
            text: result.data[i].equipPropName,
          };

          options.push(option);
        }

        return options;


      } catch (err) {
        new AppErrorHandler(err)
        .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
          .createBizHandle(this.ERR_KIND.MISSING_DATA, err.response.data.detail, this.alertDanger)
      }
    },
    setFields() {
      this.fields = [
        { key: "checked", label: "", tdClass: "detailChedk" },
        { key: "propCodeName", label: global.xe.$t("설정값") },
      ];
    },
    setItems() {
      this.items = this.isNew.ctrlPropList;
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].checked = false;
      }
    },
    setPropName(item) {
      for (let i = 0; i < this.ctrlDataOptions.length; i++) {
        if (item.propCode === this.ctrlDataOptions[i].value) {
          item.propCodeName = this.ctrlDataOptions[i].text;
          break;
        }
      }
    },
    rowInsert() {
      this.items.push({
        checked: false,
        propCode: "",
        propCodeName: "",
      });
    },
    rowRemove() {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].checked === true) {
          this.items.splice(i, 1);
          --i;
        }
      }
    },
    onRowSelected(data) {
      data.checked = !data.checked;
    },
  },
};
</script>

<style lang="scss">
.TypeControlDetailNew {
  .row {
    padding: 10px;

    .labels {
      padding: 0;
      align-self: center;
    }
  }

  .detailChedk {
    width: 50px;
    vertical-align: middle;
    padding-left: 15px;
  }
}
</style>
