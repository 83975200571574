<template>  
  <div class="mt-1 text-right">
    <button type="button" class="ml-3 btn btn-primary" @click="onNew()">{{$t('신규')}}</button>
    <!-- <button type="button" v-show="!isRegist" class="ml-2 btn btn-danger" @click="onRemove()">삭제</button> -->
  </div>
</template>

<script>

export default {
  name: "TypeControlDetailBtn",
  components: {},
  props: ["isRegist"],
  watch: {},
  data() {
    return {
    };
  },
  computed: {
    
  },
  created() {
  },
  methods: {
    onNew(){
      this.$emit('new');
    }
  },
};
</script>
