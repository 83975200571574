<template>
  <vvo v-slot="v">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-lg-8">
          <div class="TypeControlDetail bg-white">
            <div class="TypeControlDetailTitle">
              □ {{$t('설비별 제어항목')}}
            </div>
            <div class="TypeControlDetailTable" v-if="data !== null && ctrlList !== null">
              <div>
                <div class="row">
                  <b-col cols="6">
                    <b-form-group label-cols="4" label-cols-lg="4" content-cols="8" content-cols-lg="8" :label="$t('설비 유형')"
                      label-for="input-default">
                      <b-form-input v-model="data.equipTypeCodeName" disabled> </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="2"> </b-col>
                  <b-col cols="4">
                    <type-control-detail-btn @new="onNew"></type-control-detail-btn>
                    <!-- <typePropertyDetailBtn @update="onModify" @delete="onRemove"></typePropertyDetailBtn> -->
                  </b-col>
                </div>
                <div>
                  <type-control-detail-table :data="ctrlList" @update="onModify"></type-control-detail-table>
                  <!-- <type-property-detail-table :items="propertys" :equipTypeCode="data.equipTypeCode" @firstInput="firstProperty"></type-property-detail-table> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="controlDetailNew" :title="$t('제어 항목 추가')">
        <div>
          <type-control-detail-new :isNew="isNewData" @update="onModify"></type-control-detail-new>
        </div>

        <template #modal-footer>
          <div>
            <b-button @click="onValidate(v, onSave)" variant="primary" :style="{ marginLeft: '5px', marginRight: '5px' }">{{$t('저장')}}</b-button>
            <b-button @click="onCancel()" variant="danger"
              :style="{ marginLeft: '5px', marginRight: '5px' }">{{$t('취소')}}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </vvo>
</template>

<script>
import backEndApi from "../../../api/backEndApi";

import typeControlDetailBtn from "./TypeControlDetailBtn.vue";
import typeControlDetailTable from "./TypeControlDetailTable.vue";
import typeControlDetailNew from "./TypeControlDetailNew.vue";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts"
import { StatusCodes } from "http-status-codes";

export default {
  name: "TypeControlDetail",
  props: ["data"],
  components: {
    typeControlDetailBtn,
    typeControlDetailTable,
    typeControlDetailNew,
  },
  data() {
    return {
      ctrlList: [],
      isNewData: {},
    };
  },
  created() {
    console.log(this.data);
  },
  watch: {
    data(newVal, oldVal) {
      if (oldVal === null || newVal.equipTypeCode !== oldVal.equipTypeCode) {
        this.getEquipCtrlList(newVal);
      }
    },
  },
  methods: {
    async getEquipCtrlList(param) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        let result = await backEndApi.equipCtrl.getEquipCtrlList(param.equipTypeCode);
        this.ctrlList = result.data;
      } catch (err) {
                
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
          .createBizHandle(this.ERR_KIND.MISSING_DATA, err.response.data.detail, this.alertDanger)
          .errHandling();
        this.ctrlList = [];
      }
    },

    onModify() {
      // console.log(this.data);
      this.getEquipCtrlList(this.data);
    },
    onRemove() { },
    async onSave() {

      let propList = this.isNewData.ctrlPropList;

      for (let i = 0; i < propList.length; i++) {
        delete propList[i].checked;
      }

      let param = {
        equipTypeCode: this.isNewData.equipTypeCode,
        ctrlCode: this.isNewData.ctrlCode,
        equipCtrlName: this.isNewData.equipCtrlName,
        rmk: this.isNewData.rmk,
        enabled: this.isNewData.enabled,
        ctrlPropList: JSON.stringify(propList),
      };

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        await backEndApi.equipCtrl.insertEquipCtrl(param);
        this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
        this.onModify();
        this.$bvModal.hide("controlDetailNew");
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
    onCancel() {
      this.$bvModal.hide("controlDetailNew");
    },
    onNew() {
      console.log(this.data);
      this.isNewData = {
        equipTypeCode: this.data.equipTypeCode,
        ctrlCode: "",
        equipCtrlName: "",
        rmk: "",
        enabled: this.data.enabled,
        ctrlPropList: [],
      };

      this.$bvModal.show("controlDetailNew");
    },
  },
};
</script>

<style lang="scss" scoped>
.TypeControlDetail {
  border: 1px solid #313131;
  border-radius: 5px;

  .TypeControlDetailTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
  }

  .TypeControlDetailTable {
    padding: 15px;
    margin: 5px;
    border: 1px solid #000000;
  }
}
</style>
