<template>
  <div class="col-xl-12" ref="TypeControlList">
    <div class="row">
      <div class="col-lg-12">
        <div class="TypeControlList bg-white">
          <div class="TypeControlListTitle">
            □ {{$t('설비 유형')}}
          </div>
          <div class="TypeControlListTable">
            <type-control-table :data="items" @selectType="selectTypeEmit"></type-control-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";
import typeControlTable from "./TypeControlTable.vue";

export default {
  name: "TypeControlList",
  components: {
    typeControlTable,
  },
  data() {
    return {
      items: null,
    };
  },
  created() {
    this.getEquipType();
  },
  methods: {
    getEquipType() {
      backEndApi.equipType.getEquipTypeList().then(({ data }) => {
        this.items = data;
      });
    },
    selectTypeEmit(value) {
      this.$emit("selectType", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.TypeControlList {
  border: 1px solid #313131;
  border-radius: 5px;
  .TypeControlListTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
  }

  .TypeControlListTable {
    padding: 15px;
    margin: 5px;
    border: 1px solid #000000;
  }
}
</style>
