<template>
  <div class="col-md-12 default">
    <type-control />
  </div>
</template>

<script>
import typeControl from "./TypeControl.vue";

export default {
  name: 'TypeControlComponent',
  components: {
   typeControl
  },
  props: ["type"],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
