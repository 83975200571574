<template>
  <div class="TypeControlDetailTable">
    <b-table hover bordered head-variant="dark" no-border-collapse show-empty sticky-header ref="selectableTable"
      :class="'text-center mb-0 rounded'" :items="items" :fields="fields" :empty-text="$t(`등록된 내용이 없습니다.`)"
      :select-mode="'single'" :details-td-class="'rowDetailStyle'" selectable @row-selected="onRowSelected" @row-clicked="onRowDetail">
      <template #empty="scope">
        <h4>{{ $t(scope.emptyText) }}</h4>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span>&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span>&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #row-details="row" :class="'bg-white'">
        <vvo v-slot="v">
        <b-card>
          <div class="mt-1 text-right">
            <button type="button" class="ml-3 btn btn-primary" @click="onValidate(v, onModify)">{{$t('저장')}}</button>
            <button type="button" class="ml-2 btn btn-danger" @click="onRemove">{{$t('삭제')}}</button>
          </div>
          <div>
            <type-control-detail-in-table v-if="selectDetailData !== null" :rowData="row.item"
              :detailData="selectDetailData"></type-control-detail-in-table>
          </div>
        </b-card>
      </vvo>
      </template>
    </b-table>
  </div>
</template>

<style lang="scss">
.b-table-sticky-header {
  max-height: 760px;
}

.MeterTypeTable {
  .mc eterTypeCodeName {
    text-align: left;
  }

  .meterTypemDetailDsc {
    text-align: left;
  }
}
</style>

<script>
import backEndApi from "@src/api/backEndApi";

import typeControlDetailInTable from "./TypeControlDetailInTable.vue";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts"

export default {
  components: {
    typeControlDetailInTable,
  },
  props: ["data"],
  data() {
    return {
      fields: null,
      items: null,
      emptyText: null,
      selectedRow: [],
      selectDetailData: null,
    };
  },
  watch: {
    data() {
      this.selectedRow._showDetails = !this.selectedRow._showDetails;
      this.setItems();
    },
  },
  created() {
    this.setFields();
    this.setItems();
  },
  mounted() { },
  methods: {
    onRowSelected(item) {
      if (!item.length > 0) {
        this.selectedRow._showDetails = false;
        this.selectedRow = [];
      }
    },
    onRowDetail(row) {
      // 이전 선택된 row close
      this.selectedRow._showDetails = false;

      // 선택된 row open
      row._showDetails = true;

      // 선택된 row 저장
      this.selectedRow = row;


      /**
       * NOTE - DOM 업데이트가 끝나기 전까진 showDetails 값이 true로 리턴
       *        DOM 업데이트 후 API 호출을 위해 nextTick에서 처리
       *        false일때 API호출하는 것은 의미가 없기 때문에
       */
      this.$nextTick().then(async () => {
        if (row._showDetails === true) {
          this.selectDetailData = await this.getDetailCtrl(row);
        }
      });
    },

    async getDetailCtrl(row) {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        let result = await backEndApi.equipCtrl.getEquipCtrl(row.equipTypeCode, row.ctrlCode);
        return result.data;
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }
    },
    clearSeclected() {
      this.$refs.selectableTable.clearSelected();
    },
    setFields() {
      this.fields = [
        { key: "index", label: global.xe.$t("순번") },
        { key: "equipCtrlName", label: global.xe.$t("제어 항목명"), tdClass: "equipCtrlName" },
        { key: "enabled", label: global.xe.$t("사용여부") },
      ];
    },
    setItems() {
      this.items = this.data;
    },
    async onModify() {
      let propList = this.selectDetailData.ctrlPropList;

      for (let i = 0; i < propList.length; i++) {
        delete propList[i].checked;
      }

      let param = {
        ctrlCode: this.selectDetailData.ctrlCode,
        ctrlPropList: JSON.stringify(propList),
        enabled: this.selectDetailData.enabled,
        equipCtrlName: this.selectDetailData.equipCtrlName,
        equipTypeCode: this.selectDetailData.equipTypeCode,
        rmk: this.selectDetailData.rmk,
      };

      // console.log(param);

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        await backEndApi.equipCtrl.updateEquipCtrl(param);
        this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
        this.$emit("update");
      } catch (err) {
        new AppErrorHandler(err)
          .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
          .errHandling();
      }

      // let param

      // this.$refs.detail.onModify();
    },
    async onRemove() {
      this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE).then(async (data) => {
        if (!data.value) return;

        // 이전 리비전과 소스 에서 아래와 같이 변경함.
        /*           
          ↓↓↓↓↓
        */
        try {
          await backEndApi.equipCtrl.deleteEquipCtrl(this.selectDetailData.equipTypeCode, this.selectDetailData.ctrlCode);
          this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
          this.$emit("update");
        } catch (err) {
          new AppErrorHandler(err)
            .setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
            .errHandling();
        }

      });

      // this.$refs.detail.onRemove();
    },
    onReset() {
      // this.$refs.detail.onReset();
    },
    reSearch() {
      this.$emit("reSearch");
    },
  },
};
</script>
